<template>
  <div>
    <Header />
    <Navigation />
    <Content />
  </div>
</template>

<script>
import Content from "./Content.vue";
import Header from "./Header.vue";
import Navigation from "./Navigation.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Content,
    Navigation
  },
};
</script>

<style scoped></style>
