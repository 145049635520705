<template>
  <div class="content-wrapper px-4 py-2">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style scoped></style>